import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import {DialogContent, makeStyles, TableCell} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import TableHead from '@material-ui/core/TableHead';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import _, {isEmpty} from 'lodash';
import {
    getIntern,
    getInternCourses,
    getLoading,
    showIntern,
    updatePointIntern,
} from '../../../features/user/userSlice';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {TextArea} from 'semantic-ui-react';

import {Card, CardBody, CardHeader, Collapse} from 'reactstrap';
import CircularIndeterminate
    from '../../../components/Loading/CircularIndeterminate';
import {
    getScoreOfType,
    internScoreType,
    internScoreTypeHasComment,
} from '../../../configs/internCofig';
import {dehaDivisions} from '../../../configs/school';
import {positions} from '../../../configs/app';

const useStyles = makeStyles({

    editInternModal: {
        padding: '0 2rem',
    },
    numberPracticeDay: {
        marginRight: '2.1rem',
    },
    dayData: {
        marginTop: '-1.5rem',
    },
    internDateInfor: {
        width: '300px',
        minWidth: '300px',
    },
    textAreaEvaluate: {
        resize: 'none',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        whiteSpace: 'normal',
        borderRadius: '3px',
        borderColor: '#c0c0c0',
        color: '#838383',
        transition: 'all 0.5s ease',
        '&:focus': {
            height: '95px',
            WebkitLineClamp: '6',
        },

        '&::-webkit-input-placeholder': {
            color: '#83838385',
        },

        '&:-moz-placeholder': { /* Firefox 18- */
            color: '#83838385',
        },

        '&::-moz-placeholder': {  /* Firefox 19+ */
            color: '#83838385',
        },

        '&:-ms-input-placeholder': {
            color: '#83838385',
        },

        '&::placeholder': {
            color: '#83838385',
        },
    },

    border_b_o: {
        borderBottom: '1px solid #fff !important',
    },
    border_b: {
        borderBottom: '1px solid #cad1d7 !important',
    },
    valignCenter: {
        verticalAlign: 'baseline !important',
    },
    percent_worker: {
        width: '63px',
        height: '22px',
        fontSize: '1rem',
        border: 'none',
        backgroundColor: 'unset',
        cursor: 'pointer',
    },

    percent_division: {
        width: '100px',
        height: '22px',
        fontSize: '1rem',
        border: 'none',
        backgroundColor: 'unset',
        cursor: 'pointer',
    },
    w_4: {
        width: '4rem !important',
    },
    selectReactStyle: {
        marginTop: '15px',
        marginBottom: '10px',
        width: '15rem',
    },
    courseTitle: {
        paddingLeft: '0 !important',
        width: 'max-content',
        paddingTop: '0',
        paddingBottom: '0',
        border: '0',
    },
    show_point_card: {
        boxShadow: 'none !important',
    },
    inputStyle: {
        width: '2.5rem',
        borderRadius: '3px',
        border: '1px solid gray',
        opacity: '50%',
    },
    buttonSaveStyle: {
        backgroundColor: '#2DCE89',
        color: 'white',
        fontSize: '12px',
    },

    inputTeamScoreStyle: {
        width: '2.5rem',
        opacity: '50%',
        border: '1px solid gray',
        borderRadius: '3px',
        height: '1.5rem',
    },
});

export default function UpdateScoreModal(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [scoreInTeam, setScoreInTeam] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isPass, setIsPass] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [dayOff, setDayOff] = useState(0);
    const [scores, setScores] = useState([]);
    const [percentWorker, setPercentWorker] = useState(100);
    const [division, setDivision] = useState('');
    const [position, setPosition] = useState('');
    const [scoreUpdate, setScoreUpdate] = useState([]);
    const courses = useSelector(getInternCourses);
    const [openedCollapses, setOpenedCollapses] = useState([]);
    const loadApi = useSelector(getLoading);
    const internEditId = props.internEditId;
    const user = useSelector(getIntern);
    useEffect(() => {
        if (user) {
            setScoreInTeam(user.score_in_team);
            setIsPass(user.is_pass);
            setDayOff(user.day_off);
            setEndDate(user.end_date);
            setPercentWorker(user?.percent_worker);
            setDivision(user.division ?? '');
            setPosition(user.position ?? '');
        }
        return () => {
        };
    }, [user]);

    function setScoreUpdateInit(scoreUpdateTemp, score) {
        let scoreIndexUpdate = _.findIndex(scoreUpdateTemp, (item) => {
            return item.intern_subject_id === score.intern_subject_id;
        });
        if (scoreIndexUpdate < 0) {
            scoreUpdateTemp.push(score);
        }
    }

    useEffect(() => {
        let result = [];
        let scoreUpdateTemp = [];
        if (courses && courses.length > 0) {
            courses.forEach(course => {
                if (course.subjects.length > 0) {
                    course.subjects.forEach(subject => {
                        result[subject.id] = {};
                        let scoreDetails = subject.score_details;
                        for (let type in internScoreType) {
                            let score = getScoreOfType(scoreDetails, type);
                            if (type === internScoreType.theory && !score?.is_has_theory)
                            {
                                setScoreUpdateInit(scoreUpdateTemp, score);
                            }
                            if (score) {
                                if (internScoreTypeHasComment[score?.type]) {
                                    let comment = score.comment;
                                    score = {...score, value: comment};
                                }
                            } else {
                                score = {
                                    intern_subject_id: subject.id,
                                    type: type,
                                    value: null,
                                };
                            }
                            result[subject.id] = {
                                ...result[subject.id],
                                [type]: score,
                            };
                        }
                    });
                }
            });
            setOpenedCollapses([`collapseCourse${courses[0].id}`]);
        }
        setScores(result);
        setScoreUpdate(scoreUpdateTemp);
        return () => {
        };
    }, [courses]);

    useEffect(() => {
        async function fetchInterInformation(userId) {
            dispatch(showIntern(userId));
        }

        if (props.showEditModal && internEditId) {
            fetchInterInformation(internEditId).then(() => {});
        }
    }, [props.showEditModal]);

    const collapsesToggle = collapse => {
        if (openedCollapses.includes(collapse)) {
            setOpenedCollapses([]);
        } else {
            setOpenedCollapses([collapse]);
        }
    };
    const validateScore = (score) => {
        if (score == '') {
            return null;
        } else if (+score > 10) {
            return 10;
        } else if (+score < 1) {
            return 1;
        } else {
            return score;
        }
    };
    const handleSetScoreInTeam = (event) => {
        let value = event.target.value;
        if (value) {
            let score = validateScore(value);
            setScoreInTeam(score);
        }
    };
    const handleUpdateScore = async () => {
        let data = {
            user_id: user.id,
            intern_score_in_team: scoreInTeam,
            scores: scoreUpdate,
            percent_worker: percentWorker,
            day_off: dayOff,
            division,
            position,
        };

        setLoading(true);
        await dispatch(updatePointIntern(data));
        NotificationManager.success('Thành công!', '', 1500);
        setLoading(false);
        props.setShowInternModal(false);
    };

    const handleClose = async () => {
        props.setShowInternModal(false);
    };

    const changeScore = (event, subjectId, type) => {
        let value = event.target.value;
        let scoreValue = validateScore(value);
        let scoresInput = [...scores];
        scoresInput[`${subjectId}`][`${type}`] = {
            ...scoresInput[`${subjectId}`][`${type}`],
            value: scoreValue,
        };
        setScores(preScores => ([...preScores, ...scoresInput]));
        setScoreChangeToScoreUpdate(scoreValue, subjectId, type);
    };

    const setScoreChangeToScoreUpdate = (value, subjectId, type) => {
        let scoresTemp = [];
        if (isEmpty(scoreUpdate)) {
            scoresTemp.push({
                type: type,
                value: value,
                intern_subject_id: subjectId,
            });
        } else {
            scoresTemp = scoreUpdate;
            let scoreIndexUpdate = _.findIndex(scoreUpdate, (item) => {
                return item.type === type && item.intern_subject_id ===
                    subjectId;
            });
            if (scoreIndexUpdate >= 0) {
                let scoreEdit = scoresTemp[scoreIndexUpdate];
                scoreEdit.value = value;
                scoresTemp[scoreIndexUpdate] = scoreEdit;
            } else {
                scoresTemp.push({
                    type: type,
                    value: value,
                    intern_subject_id: subjectId,
                });
            }
        }

        setScoreUpdate([...scoresTemp]);
    };
    const renderScores = (subjects, scores) => {
        let result = '';
        if (subjects && subjects?.length > 0 && scores && scores.length > 0) {
            result = subjects.map((subject) => {
                let scoreDetails = scores[subject.id];
                return (<TableRow key={subject.id}>
                        <TableCell
                            className={`pl-0 align-bottom ${classes.valignCenter}`}
                            component="th"
                            align="left">
                            <span>{subject.name}</span>
                        </TableCell>
                        <TableCell
                            className={`pl-0 align-bottom ${classes.valignCenter} ${classes.tableColumn}`}
                            align="center">
                            <span>{subject.limited_date ?? 0}</span>
                        </TableCell>
                        <TableCell align="center">
                            <span>{

                                subject.is_technical
                                    ?
                                    (subject.is_has_quiz
                                        ? scoreDetails[internScoreType.theory]?.['value']
                                        : <TableCell align="center">
                                            <input min={0} max={10}
                                                   value={scoreDetails[internScoreType.theory]?.['value'] ??
                                                       ''}
                                                   onChange={event => changeScore(event,
                                                       subject?.id,
                                                       'theory')}
                                                   defaultValue={''}
                                                   className={classes.inputStyle}
                                                   type="number" />
                                        </TableCell>)
                                    : (
                                        subject.is_has_quiz
                                            ? scoreDetails[internScoreType.theory]?.['value']
                                            : ''
                                    )
                            }
                            </span>
                        </TableCell>
                        {subject.is_technical
                            ?
                            (<>
                                <TableCell align="center">
                                    <input min={0} max={10}
                                           value={scoreDetails[internScoreType.practice]?.['value'] ??
                                               ''}
                                           onChange={event => changeScore(event,
                                               subject?.id,
                                               'practice')}
                                           defaultValue={''}
                                           className={classes.inputStyle}
                                           type="number" />
                                </TableCell>
                                <TableCell align="center">
                                    <input min={0} max={10}
                                           value={scoreDetails[internScoreType.attitude]?.['value'] ??
                                               ''}
                                           onChange={event => changeScore(event,
                                               subject?.id,
                                               'attitude')}
                                           defaultValue={''}
                                           className={classes.inputStyle}
                                           type="number" />

                                </TableCell>

                                <TableCell align="center">
                                    <TextArea
                                        className={`${classes.textAreaEvaluate} ${classes.valignCenter}`}
                                        placeholder={'TTS có nỗ lực? Có đúng deadline hay chậm deadline không?'}
                                        value={scoreDetails[internScoreType.hard_work]?.['value'] ??
                                            ''}
                                        onChange={event => changeScore(event,
                                            subject?.id, 'hard_work')}
                                        onFocus={event => event.target.select()}
                                        rows={1} />
                                </TableCell
                                ><TableCell align="center">
                                <TextArea
                                    className={`${classes.textAreaEvaluate} ${classes.valignCenter}`}
                                    value={scoreDetails[internScoreType.discipline]?.['value'] ??
                                        ''}
                                    onChange={event => changeScore(event,
                                        subject?.id, 'discipline')}

                                    onFocus={event => event.target.select()}
                                    placeholder={'TTS có tuân thủ nội quy, thời gian thực tập không? Có đi muộn, về sớm? Có thái độ chống đối?'}
                                    rows={1} />

                            </TableCell>
                                <TableCell align="center">
                                    <TextArea
                                        className={`${classes.textAreaEvaluate} ${classes.valignCenter}`}
                                        value={scoreDetails[internScoreType.honest]?.['value'] ??
                                            ''}
                                        onChange={event => changeScore(event,
                                            subject?.id, 'honest')}
                                        placeholder={'TTS có tự làm bài? Có copy bài tập? Có nói dối, quanh co?'}
                                        onFocus={event => event.target.select()}
                                        rows={1} />
                                </TableCell><TableCell align="center">
                                <TextArea
                                    className={`${classes.textAreaEvaluate} ${classes.valignCenter}`}
                                    value={scoreDetails[internScoreType.communicate]?.['value'] ??
                                        ''}
                                    onChange={event => changeScore(event,
                                        subject?.id, 'communicate')}
                                    onFocus={event => event.target.select()}
                                    placeholder={'TTS có hoạt ngôn, hòa đồng? Có giúp đỡ các bạn, vui vẻ, nhiệt tình?'}

                                    rows={1} />
                            </TableCell>
                            </>) : null
                        }
                        <TableCell colSpan={subject.is_technical ? 1 : 7}
                                   className={`pr-0 align-bottom ${classes.valignCenter}`}
                                   align="right">
                            {subject?.score ?
                                parseFloat(subject?.score).toFixed(1)
                                : ''}
                            {subject?.score ||
                            !(subject.is_has_quiz && subject.is_technical)
                                ? (
                                    subject?.is_pass
                                        ? <CheckIcon style={{color: 'green'}} />
                                        : <CloseIcon style={{color: 'red'}} />
                                )
                                : 'NA'
                            }
                        </TableCell>
                    </TableRow>
                );
            });
        }

        return result;
    };

    const renderOptionWorkerPercent = () => {
        let result = [];
        for (let percent = 100; percent >= 50; percent -= 5) {
            result.push(<option key={`percent-${percent}`}
                                value={percent}>{percent}%</option>);
        }
        return result;
    };

    const getInternClassStatus = status => {
        if (/Chậm/.test(status)) {
            return 'text-danger';
        } else if (/Đúng/.test(status)) {
            return 'text-success';
        } else {
            return 'text-warning';
        }
    };

    const renderOptionDivision = () => {
        let result = [];
        for (let division of dehaDivisions) {
            result.push(<option key={`division-${division}`}
                                value={division}>{division}</option>);
        }
        return result;
    };

    const renderOptionPosition = () => {
        let result = [];
        for (let position of positions) {
            result.push(<option key={`position-${position}`}
                                value={position}>{position}</option>);
        }
        return result;
    };
    const renderCourses = courses => {
        let result = '';
        if (courses && courses.length > 0) {
            result = courses.map(item => {
                return (
                    <div key={`course-item${item.id}`} className="accordion">
                        <Card
                            className={`card-plain ${classes.show_point_card}`}>
                            <CardHeader
                                role="tab"
                                onClick={() => collapsesToggle(
                                    `collapseCourse${item.id}`)}
                                className={`ml-0 ${classes.courseTitle}`}
                                aria-expanded={openedCollapses.includes(
                                    `collapseCourse${item.id}`,
                                )}
                            >
                                <span className={`mr-4`}>{item.name}</span>
                            </CardHeader>
                            <Collapse
                                role="tabpanel"
                                isOpen={openedCollapses.includes(
                                    `collapseCourse${item.id}`)}
                            >
                                <CardBody>
                                    <TableContainer>
                                        <Table
                                            className={'w-100 table table-hover'}
                                            aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        className={`pl-0 pb-0 ${classes.border_b_o}`}
                                                        align="left">Môn
                                                        học</TableCell>
                                                    <TableCell
                                                        className={`pb-0 ${classes.border_b_o}`}
                                                        align="left">Thời gian
                                                        học</TableCell>
                                                    <TableCell colSpan={3}
                                                               className={`pl-0 pb-0 ${classes.border_b_o}`}
                                                               align="center">Điểm</TableCell>
                                                    <TableCell colSpan={4}
                                                               className={`pl-0 pb-0 ${classes.border_b_o}`}
                                                               align="center">Nhận
                                                        xét</TableCell>
                                                    <TableCell
                                                        className={`pr-0 pb-0 ${classes.border_b_o}`}
                                                        align="right">Tổng&nbsp;</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        className={'pl-0 pt-0'}
                                                        align="left"></TableCell>
                                                    <TableCell
                                                        className={'pt-0'}
                                                        align="center">(Ngày)</TableCell>
                                                    <TableCell
                                                        className={'pt-0'}
                                                        align="center ">Lý
                                                        thuyết</TableCell>
                                                    <TableCell
                                                        className={'pt-0'}
                                                        align="center">Thực
                                                        hành&nbsp;</TableCell>
                                                    <TableCell
                                                        className={'pt-0'}
                                                        align="center">Thái
                                                        độ&nbsp;</TableCell>

                                                    <TableCell
                                                        className={'pt-0'}
                                                        align="center">Chăm
                                                        chỉ</TableCell>
                                                    <TableCell
                                                        className={'pt-0'}
                                                        align="center">Kỉ
                                                        luật &nbsp;</TableCell>
                                                    <TableCell
                                                        className={'pt-0'}
                                                        align="center">Trung
                                                        thực &nbsp;</TableCell>
                                                    <TableCell
                                                        className={'pt-0'}
                                                        align="center">Giao
                                                        tiếp&nbsp;</TableCell>
                                                    <TableCell
                                                        className={'pr-0 pt-0'}
                                                        align="right">&nbsp;</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {renderScores(item.subjects,
                                                    scores)}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardBody>
                            </Collapse>
                        </Card>
                    </div>);
            });
        }
        return result;
    };
    return (
        <div>
            {loadApi || loading ? (<CircularIndeterminate />
            ) : ''}
            <Dialog
                maxWidth={'xl'}
                className={`${classes.editModalDialog} modalEditIntern `}
                open={props.showEditModal}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent className={`${classes.DialogContent}`}>
                    <h2>Kết quả học tập</h2>
                    <div>
                        <p className={'mb-0'}>Họ tên: {user.name}</p>

                        <Grid className={'d-flex flex-row'}>
                            <Grid
                                className={`d-flex flex-column ${classes.internDateInfor}`}>
                                <p className={'mb-0'}>Email: {user.email}</p>
                                <p className={'mb-0'}>Ngày bắt
                                    đầu: {user.start_date}</p>
                                <p className={'mb-0'}>
                                    Ngày kết thúc: {endDate ??
                                    'Chưa kết thúc'}</p>
                                <Grid>
                                    <span>Thời lượng thực tập:</span>
                                    <select name="percent_worker"
                                            value={percentWorker}
                                            title="Choose year"
                                            className={classes.percent_worker}
                                            onChange={e => setPercentWorker(
                                                e.target.value)}
                                    >
                                        {renderOptionWorkerPercent()}
                                    </select>
                                </Grid>
                                <p className={'mb-0'}>
                                    Công nghệ: {user?.technology}</p>
                                <Grid>
                                    <span>Bộ phận:</span>
                                    <select name="division"
                                            value={division}
                                            title="Choose division"
                                            className={classes.division}
                                            onChange={e => setDivision(
                                                e.target.value)}
                                    >
                                        {renderOptionDivision()}
                                    </select>
                                </Grid>
                            </Grid>
                            <Grid className={'d-flex flex-column'}>
                                <div className={'mb-0'}><span
                                    className={'pr-2'}>Số ngày nghỉ</span>
                                    <input min={0} max={30}
                                           defaultValue={0}

                                           value={dayOff}
                                           onChange={event => setDayOff(
                                               event.target.value)}
                                           className={`${classes.w_4} ${classes.inputStyle}`}
                                           step={0.5}
                                           type="number" />

                                </div>
                                <p className={'mb-0'}>Số ngày thực
                                    tập: {user.date_training_total}</p>
                                <p className={'mb-0 text-ovf1'}>Kế hoạch tuyển
                                    dụng: {user?.recruitment_name
                                        ? user?.recruitment_name
                                        : 'NA'}
                                </p>
                                <p className={'mb-0'}>
                                    Ngày lên team dự
                                    tính: {user?.predict_end_date}</p>
                                <p className={'mb-0'}>
                                    Tiến độ:
                                    <span className={getInternClassStatus(
                                        user?.intern_training_status_str)}> {user?.intern_training_status_str}</span>
                                </p>

                                <Grid>
                                    <span>Vị trí:</span>
                                    <select name="position"
                                            value={position}
                                            title="Choose position"
                                            className={classes.division}
                                            onChange={e => setPosition(
                                                e.target.value)}
                                    >
                                        {renderOptionPosition()}
                                    </select>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid>
                            <span>Khóa học:</span>
                        </Grid>
                        {renderCourses(courses)}
                        <Grid className={'d-flex justify-content-between'}>
                            <p className={'mb-0'}>Tổng kết:</p>
                            <p className={'mb-0'}>
                                <b>{user.avg_score ?
                                    parseFloat(user.avg_score ?? 'NA').toFixed(1) :
                                    'NA'}</b>
                            </p>
                        </Grid>
                        <Grid className={'d-flex justify-content-between'}>
                            <p className={'mb-0'}>Kết quả thực tập:</p>
                            <p className={'mb-0'}>
                                {isPass == null
                                    ? (<b>NA </b>)
                                    : (isPass == true ? <span
                                                className={'text-success'}>Pass </span>
                                            : <span
                                                className={'text-danger'}> Fail </span>
                                    )
                                }
                            </p>
                        </Grid>
                        {
                            user.is_pass
                                ? (
                                    <Grid
                                        className={'d-flex justify-content-between'}>
                                        <p>Đánh giá trên team:</p>
                                        <p className={classes.teamScore}
                                           align="right">
                                            <input max={10}
                                                   min={0}
                                                   onChange={event => handleSetScoreInTeam(
                                                       event)}
                                                   value={scoreInTeam ?? ''}
                                                   defaultValue={''}
                                                   className={classes.inputTeamScoreStyle}
                                                   type="number" />
                                        </p>
                                    </Grid>)
                                : ''
                        }
                    </div>
                    <Grid className={'d-flex justify-content-end'}>
                        <Button
                            onClick={handleUpdateScore}
                            disabled={loading}
                            className={classes.buttonSaveStyle}>
                            {loading
                                ? (
                                    <>Saving ...
                                    </>
                                )
                                : ('Save')
                            }
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <NotificationContainer />
        </div>
    );
}

UpdateScoreModal.propTypes = {
    setShowInternModal: PropTypes.func,
    showEditModal: PropTypes.bool,
    internEditId: PropTypes.number,
};